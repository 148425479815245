.auto-complete-btn{
    background-color: transparent !important;
    display: block;
    width: 100%;
    text-align: left;
    color: #757575 !important;
    padding: 6px;
    border: 1px solid #e9ecef;
    border-radius: 3px;
}
.auto-complete-menu {
    padding: 5px;
    background-color: #eceff1;
    position: absolute !important;
    list-style: none;
  }
  .auto-complete-menu li {
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
  }
  .auto-complete-menu li:hover {
    background-color: #f5f5f5;
  }
  .auto-complete-menu input{
    margin-bottom: .35rem;
}
.auto-complete-menu button{
    padding: .35rem;
}