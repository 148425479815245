.family-report-container {
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.family-report-table {
  flex: 1 1 20%;
  border-radius: 10px;
  padding: 20px;
}
