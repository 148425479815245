/* Remove spinner arrows in Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Remove spinner arrows in Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* Alert in TR  */
.alert-warning {
    width: 100%;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ffc107;
    border-radius: 0.25rem;
    background-color: #fff3cd;
    color: #856404;
    margin-top: 0.25rem;
  }
  

  /* Image Upload */
  .image-upload {
    border: 1px solid #ced4da;
    cursor: pointer;
    text-align: center;
    width: 150px;  
    height: 150px;  
    overflow: hidden;
    border-radius: 50%;  
    position: relative;  
    display: flex;
    align-items: center; 
    justify-content: center; 
  }
  
  .image-upload p {
    margin: 0;
    font-size: 14px;  
    color: #6c757d;  
    text-align: center;
  }
  
  
  .mt-2 {
    margin-top: 0.5rem;
  }
  
  .ms-2 {
    margin-left: 0.5rem;
  }
  

  /* Modal */
  .modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
     z-index: 1050; 
   }
  
 
  
  .modal-body p {
    text-align: center; 
     margin: 0;
    font-size: 1rem;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .btn-close {
    background: none;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
  }
  
  
  
  