.overcard {
  display: block;
  width: 305px;
  height: 340px;
  background: white; 
  border-radius: 10px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2); 
  position: fixed;
  top: 55%;
  left: 58%;
  transform: translate(-50%, -50%);
  z-index: 9999; 
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-bar {
  width: 100%;
  margin-bottom: 10px;
}

.input-container {
  position: relative;
  width: 100%;
} 

.input {
  width: 100%;
  padding: 8px 10px 8px 30px; 
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

button.close-button {
  background: #6366f1;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #aaa;
  pointer-events: none; 
}

button.close-button:hover {
  background-color: #4f46e5;
}

.scrollable-list {
  max-height: 200px; 
  overflow-y: auto; 
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  list-style: none;
  margin: 0;
}

.list li {
  padding: 8px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.list li:hover {
  background-color: #f0f0f0;
}

.list li:last-child {
  border-bottom: none;
}

.no-result {
  text-align: center;
  color: #999;
  font-style: italic;
}
