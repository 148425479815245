.table-section {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; 
  gap: 15px; 
}

.event-table {
  flex-basis: calc(50% - 20px); 
  height: 100px;
  border: 2px solid #9b9bda;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #e8e8e8;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
} 
.custom-button {
  position: relative;
  background: #ffffff;
  color: #000;
  padding: 15px;
  margin: 10px;
  border-radius: 10px;
  width: 150px;
  height: 50px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.custom-button:hover,
.custom-button:hover .tooltip,
.custom-button:hover .tooltip::before {
  background: linear-gradient(320deg, rgb(3, 77, 146), rgb(155, 155, 218)
  );
  color: #ffffff;
}

.tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.custom-button:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
